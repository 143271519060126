import {createRouter, createWebHashHistory, useRouter} from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import login from '../views/index/login.vue'
import index from '../views/index/index.vue'
import wsDemo from '../views/index/wsDemo.vue'
import store from '../store'
import tools from '../utils/tools'

//静态路由
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/wsDemo',
    name: 'wsDemo',
    component: wsDemo,
  },
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '新手教程',
          icon: ''
        },
        component: () => import('@/views/index/home')
      },  //此home用户登录跳转,在组建完路由后会被替换
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: login,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});

// 路由加载前
router.beforeEach((to, from, next) => {
  NProgress.configure({ showSpinner: false })
  NProgress.start();
  //如果是跳转首页,直接跳过去
  if(to.name == 'login'){
    next();
  }
  //如果菜单集合为空
  else if(store.state.menuList.length == 0){
    tools.setInfo().then(res => {
      //刷新
      if(!from.name){
        //除了home页,跳指定页
        if(to.path && to.path != '/home'){
          router.push({
            path: to.path
          })
        }
        //有缓存页,跳转缓存页
        else if(localStorage.getItem('routerName') && to.name != localStorage.getItem('routerName')){
          router.push({
            name: localStorage.getItem('routerName')
          })
        }
        //无缓存,无指定页跳转首页
        else {
          next({
            name:'login'
          });
        }
      }
      //其他页面跳转
      else{
        next();
      }
      //设置tab列
      tools.settingTabList(to);
    }).catch(err => {
      // console.log("路由信息错误", err);
      //获取信息出错,清空token,回到登录
      localStorage.setItem("tokenValue", "");
      localStorage.setItem("routerName", "");
      next({
        name:'login'
      });
    })
  }else{
    next();
    //设置tab列
    tools.settingTabList(to);
  }
});

// 路由加载后
router.afterEach(() => {
  NProgress.done()
});

export default router
